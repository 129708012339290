import React from 'react'
import ImageWrap from 'components/ImageWrap'
import ButtonLink from 'components/ButtonLink'
import Inview from 'components/Inview'
import { useLocalization } from 'gatsby-theme-i18n'
import { tr } from 'content/strings'

export default function FeaturedItemBlock({ item, set, itemType }) {
  const { locale } = useLocalization()

  const itemFull = set.find((setItem) => setItem.id === item.strapiId)
  const { title, excerpt, image, slug, productCollection } = itemFull

  let pretitle, link
  if (itemType === 'diamond') {
    pretitle = tr('relatedDiamond', locale)
    link = `/diamonds/${slug}/`
  }
  if (itemType === 'product') {
    pretitle = tr('relatedProduct', locale)
    if (productCollection) {
      link = `/collections/${productCollection.slug}/`
    }
  }

  return (
    <section className='featured-item-block pad-v-standard no-collapse'>
      <div className='container'>
        <Inview className='text fade-in up'>
          <div className='wrap'>
            <h3>{pretitle}</h3>
            {title && <h2 dangerouslySetInnerHTML={{ __html: title }} />}
            <div className='border' />
            {excerpt && <p dangerouslySetInnerHTML={{ __html: excerpt }} />}
            <ButtonLink to={link} />
          </div>
        </Inview>
        <Inview className='image fade-in up'>
          <div className='inner'>
            <ImageWrap image={image} />
          </div>
        </Inview>
      </div>
    </section>
  )
}
