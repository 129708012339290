import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'

export default function SlideshowCenteredBlock({ content, loop = true }) {
  const { title, images } = content
  SwiperCore.use([Navigation, Pagination, Autoplay])
  return (
    <div className='slideshow-centered-block pad-v-standard'>
      <div className='title-wrap container'>
        <div className='inner'>
          <h3>{title}</h3>
        </div>
      </div>
      <div className='inner'>
        <Swiper
          loop={loop}
          loopAdditionalSlides={1}
          breakpoints={{
            0: {
              slidesPerView: 1.1,
            },
            601: {
              slidesPerView: 2,
            },
          }}
          spaceBetween='10%'
          centeredSlides={true}
          speed={800}
          autoplay={{
            delay: 3000,
          }}
          watchOverflow={true}
        >
          {images.map((image, i) => (
            <SwiperSlide key={i}>
              <Inview className='image fade-in-up'>
                <ImageWrap image={image} />
              </Inview>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}
