export const scrollToContact = () => {
  const block = document.querySelector('.BlockContact')
  const nav = document.querySelector('.nav')
  const blockY = block.offsetTop + nav.offsetHeight
  window.scrollTo({
    top: blockY,
    behavior: 'smooth',
  })
}

export const makeYellow = (filename) => {
  const arr = filename.split('.')
  const newFilename = `${arr[0]}_yellow.${arr[1]}`
  return newFilename
}
export const getNextItems = (allItems, thisItemSlug, length) => {
  const thisIndex = allItems.findIndex((item) => item.slug === thisItemSlug)
  const allItems2 = allItems.concat(allItems)
  let allItemsSlice = allItems2.slice(thisIndex + 1, thisIndex + 1 + length)
  allItemsSlice = allItemsSlice.filter((item) => item.slug !== thisItemSlug)
  const allItemsUnique = [...new Set(allItemsSlice)]
  return allItemsUnique
}
