import React from 'react'
import { graphql } from 'gatsby'
import { useLocalization } from 'gatsby-theme-i18n'
import { tr } from 'content/strings'
import NewsIntroBlock from 'components/blocks/NewsIntroBlock'
import BannerTitleOnlyBlock from 'components/blocks/BannerTitleOnlyBlock'
import SingleImageBlock from 'components/blocks/SingleImageBlock'
import BodyBlock from 'components/blocks/BodyBlock'
import FeaturedItemBlock from 'components/blocks/FeaturedItemBlock'
import FeaturedCollection from 'components/FeaturedCollection'
import SlideshowCenteredBlock from 'components/blocks/SlideshowCenteredBlock'
import SlideshowMoreItemsBlock from 'components/blocks/SlideshowMoreItemsBlock'
import { getNextItems } from 'js/utils'

export default function AwardsPost({ data }) {
  const { locale } = useLocalization()
  const page = data.strapiAwardsPost
  if (!page) return false
  const allProducts = data.allStrapiProduct.nodes
  const allCollections = data.allStrapiCollection.nodes
  const parent = data.strapiAwardsPage
  const allAwardsPosts = data.allStrapiAwardsPost.nodes

  const { bannerImageOnlyBlock, awardsPostBuilder } = page
  const customBanner = { pretitle: parent.title }

  const nextAwardsPosts = getNextItems(allAwardsPosts, page.slug, 4)
  const customSlideshowBlock = {
    title: tr('moreAwards', locale),
    slides: nextAwardsPosts.map((post) => ({
      image: post.bannerImageOnlyBlock?.image,
      date: post.date,
      title: post.title,
      link: `/awards/${post.slug}/`,
    })),
  }

  return (
    <div className='page-awards-post'>
      <BannerTitleOnlyBlock
        content={customBanner}
        padTop={true}
        lineBottom={true}
      />
      {bannerImageOnlyBlock?.image && (
        <SingleImageBlock content={bannerImageOnlyBlock} isBigger={true} />
      )}
      <NewsIntroBlock content={page} />
      {awardsPostBuilder && (
        <Post
          content={awardsPostBuilder}
          allProducts={allProducts}
          allCollections={allCollections}
        />
      )}
      <SlideshowMoreItemsBlock content={customSlideshowBlock} />
    </div>
  )
}

const Post = ({ content, allProducts, allCollections }) => {
  const {
    introBodyBlock,
    relatedProduct,
    bodyBlock2,
    slideshowBlock,
    relatedCollection,
  } = content

  return (
    <>
      {introBodyBlock && <BodyBlock content={introBodyBlock} />}
      {relatedProduct && (
        <FeaturedItemBlock
          item={relatedProduct.product}
          set={allProducts}
          itemType='product'
        />
      )}
      {bodyBlock2 && <BodyBlock content={bodyBlock2} />}
      {slideshowBlock && <SlideshowCenteredBlock content={slideshowBlock} />}
      {relatedCollection && (
        <FeaturedCollection
          content={relatedCollection}
          allCollections={allCollections}
          isRelatedCollection={true}
          showSlideshow={false}
        />
      )}
    </>
  )
}

export const pageQuery = graphql`
  query ($locale: String!, $slug: String!) {
    strapiAwardsPage(locale: { eq: $locale }) {
      title
    }
    strapiAwardsPost(slug: { eq: $slug }, locale: { eq: $locale }) {
      slug
      date
      title
      excerpt
      seo {
        title
        description
      }
      bannerImageOnlyBlock {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      awardsPostBuilder {
        introBodyBlock {
          body
        }
        relatedProduct {
          product {
            id
          }
        }
        bodyBlock2 {
          title
          body
        }
        slideshowBlock {
          title
          images {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        relatedCollection {
          featuredCollection {
            slug
          }
        }
      }
    }
    allStrapiAwardsPost(
      filter: { locale: { eq: $locale } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        slug
        date
        title
        bannerImageOnlyBlock {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    allStrapiCollection(
      filter: { locale: { eq: $locale } }
      sort: { fields: order }
    ) {
      nodes {
        slug
        bannerBlock {
          pretitle
          title
          excerpt
          link
          alignH
          alignV
          textColor
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          link
        }
      }
    }
    allStrapiProduct(filter: { locale: { eq: $locale } }) {
      nodes {
        strapiId
        title
        excerpt
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        productCollection {
          slug
        }
      }
    }
  }
`
